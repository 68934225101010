import React, { Fragment } from 'react';

import Layout from '../components/layout';
// import SignUpForm from '../components/SignUp';
import SectionWrapper from '../components/SectionWrapper/SectionWrapper';
import { SignInGoogle } from '../components/SignIn';

const SignUpPage = () => (
  <Fragment>
    <h1>Sign Up</h1>
    {/* <SignUpForm /> */}
    <div>Sign up is disabled. Please sign in with Google.</div>
    <SignInGoogle />
  </Fragment>
);

export default () => (
  <Layout>
    <SectionWrapper sectionId="signup">
      <SignUpPage />
    </SectionWrapper>
  </Layout>
);
